<template>
  <div class=" mt-2">
    <b-card class="mb-0" :title="$t('settings.tax_rates.titles.manage_tax_rates')">
      <b-overlay :show="loading" rounded="sm" no-fade>
        <div class="m-2">
          <b-row align-h="between">
            <b-col cols="12" md="3">
              <b-form-input
                v-model="filter"
                class="d-inline-block mr-1 mt-1"
                :placeholder="$t('general_text.search_table')"
              />
            </b-col>
     
            <b-col cols="12" md="3">
              <label>Default Rate</label>
              <b-form-input
                v-model="defaultRate"
                class="d-inline-block mr-1"
                @change="checkDefaultRate"
                type="number"
              />
            </b-col>

            <b-col v-if="$Can('add_tax_rate')" cols="12" md="3">
              <div class="d-flex align-items-center justify-content-end mt-1">
                <b-button variant="success" @click="updateTaxRates">
                  <span class="text-nowrap">{{$t('settings.tax_rates.buttons.update')}}</span>
                </b-button>
              </div>
            </b-col>
          </b-row>
        </div>

        <b-table
          striped
          hover
          ref="refUserListTable"
          class="position-relative p-2"
          :items="allTaxes"
          :filter="filter"
          filter-debounce="250"
          @filtered="onFiltered"
          :filter-included-fields="filterOn"
          selectable
          select-mode="single"
          responsive
          :small="true"
          head-variant="dark"
          :fields="tableColumns"
          show-empty
          :empty-text="$t('general_messages.no_matching_recors_found')"
          :current-page="currentPage"
          :per-page="perPage"
        >
          <template #cell(index)="data">
            <b
              ><span class="font-weight-bold">
                <strong class="text-primary">#{{ data.index + 1 }}</strong>
              </span></b
            >
          </template>
          <!-- Column: Currency -->
          <template #cell(Residency)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{
                  data.item.Residency ? data.item.Residency : "N/A"
                }}</strong>
              </span></b
            >
          </template>

          <!-- Column: Actions -->
          <template #cell(Rate)="data">
            <b-form-input
            type="number"
                min="0"
                max="1"
                v-model="data.item.Rate"
                class="d-inline-block mr-1"
                :placeholder="defaultRate"
                @change="checkRate(data.item)"
              />
          </template>
        </b-table>

        <div class="mx-2 mb-2">
          <b-row>
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
            <span class="text-muted"
                >{{$t('general_text.showing')}} {{ dataMeta.from }} {{$t('general_text.to')}} {{ dataMeta.to }} {{$t('general_text.of')}}
                {{ dataMeta.of }} {{$t('general_text.entries')}}</span
              >
            </b-col>

            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </b-overlay>
     
    </b-card>
  </div>
</template>

<script>
import {
  BOverlay,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormRating,
  VBModal,
  BTabs,
  BTab,
  BCardText,
  BIcon,
} from "bootstrap-vue";
import store from "@/store";
import { onUnmounted } from "@vue/composition-api";
import settingsStoreModule from "../../settingsStoreModule";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, alphaNum, email, min, max } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import axios from "axios";
import axiosIns from "@/libs/axios";
import router from "@/router";
import moment from "moment";
import "animate.css";
import _ from "lodash";
import { mapState, mapGetters } from "vuex";

export default {
  components: {
    BOverlay,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BTabs,
    BTab,
    BCardText,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormRating,
    BIcon,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    ToastificationContent,
  },

  directives: {
    Ripple,
    "b-modal": VBModal,
  },

  setup() {
    const SETTINGS_APP_STORE_MODULE_NAME = "settings";
    // Register module
    if (!store.hasModule(SETTINGS_APP_STORE_MODULE_NAME))
      store.registerModule(SETTINGS_APP_STORE_MODULE_NAME, settingsStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(SETTINGS_APP_STORE_MODULE_NAME))
        store.unregisterModule(SETTINGS_APP_STORE_MODULE_NAME);
    });
  },

  data() {
    return {
      allTaxes: [],
      currencyCreateModal: false,
      allCreatedCurrencies: [],
      accessLevel: null,
      filterModal: null,
      updatePermloading: false,
      createCurrencyloading: false,
      newCurrency: null,
      defaultRate:null,
      filterOn: ["Residency"],
      tableColumns: [
        {
          key: "index",
          label: "",
        },
        {
          key: "Residency",
          label: this.$t('settings.tax_rates.table.residency_name'),
          sortable: true,
        },
        { key: "Rate", label:this.$t('settings.tax_rates.table.rate'), sortable: true, thStyle:'width:25%;'},
      ],
      perPage: localStorage.getItem("currencyShow") ?? 10,
      currentPage: 1,
      totalRows: 0,
      perPageOptions: [10, 25, 50, 100],
      filter: null,

      loading: false,
    };
  },
  computed: {
    user: {
      get() {
        return this.$store.getters["authUser"];
      },
      set(newValue) {
        return this.$store.dispatch("setUser", newValue);
      },
    },
    ...mapGetters(["authUser"]),
    dataMeta() {
      return {
        from:
          this.totalRows != 0 ? this.perPage * (this.currentPage - 1) + 1 : 0,
        to:
          this.perPage * (this.currentPage - 1) + Number(this.perPage) <
          this.totalRows
            ? this.perPage * (this.currentPage - 1) + Number(this.perPage)
            : this.totalRows,
        of: this.totalRows,
      };
    },
  },
  mounted() {
    this.getTaxRates();
  },
  methods: {
    getTaxRates() {
      this.loading = true;
      store
        .dispatch("settings/getTaxRates")
        .then((res) => {
          this.allTaxes = res.data.rates;
          this.defaultRate = res.data.defaultRate;

          this.totalRows = res.data.rates.length;
          this.loading = false;
        })
        .catch((error) => {
          this.databaseErrorMessage();
          console.log(error);
        });
    },

    updateTaxRates() {
      this.loading = true;
      store
        .dispatch("settings/updateTaxRates", {'rates' : this.allTaxes, 'defaultRate' : this.defaultRate })
        .then((res) => {
          this.allTaxes = res.data.rates;
          this.defaultRate = res.data.defaultRate;
          this.totalRows = res.data.rates.length;

          this.loading = false;
        })
        .catch((error) => {
          this.databaseErrorMessage();
          console.log(error);
        });
    },

    checkRate(item){

      if (item.Rate < 0) {
        this.allTaxes.filter((e) => e==item)[0].Rate = 0

      }else if (item.Rate > 1) {
        if(item.Rate <= 100){
          this.allTaxes.filter((e) => e==item)[0].Rate = item.Rate/100

        }else{
          this.allTaxes.filter((e) => e==item)[0].Rate = 1

        }
        
      }

    },

    checkDefaultRate(){

      if (this.defaultRate < 0) {
        this.defaultRate = 0

      }else if (this.defaultRate > 1) {
        if(this.defaultRate <= 100){
          this.defaultRate = this.defaultRate/100

        }else{
          this.defaultRate = 1

        }
        
      }

      },
  
    databaseErrorMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: this.$t('settings.currency.messages.database_error'),
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    },
    existingCurrencyMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: this.$t('settings.currency.messages.currency_is_exist'),
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    currencyNotFoundMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: this.$t('settings.currency.messages.currency_not_found'),
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    createCurrencyToastMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Successfully",
          text: this.$t('settings.currency.messages.currency_create_success'),
          icon: "ThumbsUpIcon",
          variant: "success",
        },
      });
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
  watch: {
    perPage: {
      handler: function(val, before) {
        localStorage.setItem("userShow", val);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
